/* eslint no-use-before-define: 0 */  // --> OFF
import { useRoutes } from 'react-router-dom';
import HomePage from "../pages/HomePage";
import Signup from '../pages/Signup';
export default function Router() {
    {/*<AuthGuard> </AuthGuard> */} 
    return useRoutes([
        {
            path:'/',
            element: <HomePage/>,            
        },
        {
            path:'/signup',
            element: <Signup/>,            
        },          
    ])
}