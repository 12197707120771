
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import axios from "axios";
import { Navigate ,useNavigate} from 'react-router-dom';
//import useAuth from '../hooks/useAuth';
//import { read } from 'xlsx';

/*
    button-1 -> axios get request 
 */

/*

 axios.get(
            "http://ab6dc3f13e6454a258b5970269fa34c6-119812681.us-east-1.elb.amazonaws.com",
            headers
            ).
        then((response)=> {
            console.log(response)
        })

fetch('http://ab6dc3f13e6454a258b5970269fa34c6-119812681.us-east-1.elb.amazonaws.com/')
  .then(response => response.json())
    https://demoqa.com/Bookstore/v1/Books
 */

export default function HomePage() {
    const navigate = useNavigate()
    const button_1 = () => {
        console.log('start of function call');
        const headers = {"Access-Control-Allow-Origin": "*"}
        

        axios.get(
            "http://ab6dc3f13e6454a258b5970269fa34c6-119812681.us-east-1.elb.amazonaws.com",
            headers
            ).
        then((response)=> {
            console.log(response)
        }).catch((error)=>{
            console.log(error)
        })
        console.log('end of function call');        
    }

    const button_2 = async () => {
        
        console.log('start of function call');
        await fetch('/api/simplereq').then(
            (response) => {
                console.log("print from inside the then function")
                console.log(response)
                return response.json()
            }
        ).then((data)=>{
            console.log("from second then")
            console.log(data)
            console.log(data.Hello)
        })
 
        console.log('end of function call');
    }

    const button_3 = async () => {
        console.log('test function call');
        const input_param = {
            method: "POST"
        }
        await fetch('/api/simplepost',input_param).then(
            (response) => {
                console.log("print from inside the then function button 3")
                console.log(response)
                return response.json()
            }
        ).then((data)=>{
            console.log("from second then button 3")
            console.log(data)            
        })
    }
    const button_4 =  () => {
        //authContext.setIsAuthenticated(false);
        console.log("logout function called")

    }

    const button_5 =  () => {
        navigate('/signup')
    }

    const button_6 =  () => {
        navigate('/signup')
    }

    const button_7 =  () => {
        navigate('/signup')
    }


    //homepage
    return (
        <>
            
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Stack spacing={2} direction="column">
                        <Button variant="contained" onClick={button_1}>Button 1</Button>                       
                        <Button variant="contained" onClick={button_2}>Button 2</Button>
                        <Button variant="contained" onClick={button_3}>Button 3</Button>                      
                        <Button variant="contained" onClick={button_4}>Button 4</Button>
                        <Button variant="contained" onClick={button_5}>Button 5</Button>                       
                        <Button variant="contained" onClick={button_6}>Button 6</Button>
                        <Button variant="contained" onClick={button_7}>Button 7</Button>                      
                        <Button variant="contained" onClick={button_4}>Button 8</Button>
                        
                    </Stack>
                </Box>
            </Container>
        </>
    )
}

/*
      
 */